import React from "react";
import { Avatar, Box, Grid, Link, Paper, Typography } from "@mui/material";
import { capitalize } from "../../common/component/util";
import { STORAGE_BUCKET } from "../../common/component/constants";
import moment from "moment";

export default function OrderFulfillment(props) {
  return (
    <Paper elevation={0}>
      <Box px={2} py={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {props.order.location && (
              <Box>
                <Typography variant="body2">
                  {props.order.location.street}
                  {props.order.location.unit && `, ${props.order.location.unit}`}
                </Typography>
                <Typography variant="body2">
                  {props.order.location.city}, {props.order.location.state} {props.order.location.zip}
                </Typography>
                {props.type === "delivery" && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    sx={{ fontSize: "12px" }}
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
                      `${props.order.location.street}, ${props.order.location.city}, ${props.order.location.state} ${props.order.location.zip}`
                    )}`}
                  >
                    Get Directions
                  </Link>
                )}
              </Box>
            )}
            {!props.order.location && props.order.address && (
              <Box>
                <Typography variant="body2" color="error">
                  {props.order.address}
                </Typography>
                {props.type === "delivery" && props.order.address && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    sx={{ fontSize: "12px" }}
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(props.order.address)}`}
                  >
                    Get Directions
                  </Link>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} date & time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {moment(props.order.date).format("ddd, MMM DD, YYYY")} [{props.order.time}]
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{props.order.instructions}</Typography>
          </Grid>
        </Grid>
        {props.order.provider && (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Delivery Provider
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em", gap: "5px" }}>
                  <Avatar
                    style={{ width: "24px", height: "24px" }}
                    src={`${STORAGE_BUCKET}/img/logo/${props.order.provider.name.toLowerCase()}.webp`}
                  ></Avatar>
                  {props.order.provider.name}
                </Typography>
                {props.order.provider.deliveryId ? (
                  <Typography variant="caption">{props.order.provider.deliveryId}</Typography>
                ) : (
                  <Typography color="error" variant="caption">
                    Delivery Not Created!
                  </Typography>
                )}
              </Grid>
            </Grid>
            {props.order.provider.data && (
              <Box>
                {props.order.provider.data.data?.driver && (
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      DRIVER
                    </Typography>
                    <Typography variant="body1">
                      {props.order.provider.data.data.driver.name} {props.order.provider.data.data.driver.phone}
                    </Typography>
                  </Box>
                )}
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    PICKUP STATUS
                  </Typography>
                  <Typography variant="body1">{props.order.provider.data.data.pickup_stop.status}</Typography>
                  <Typography>
                    {moment(
                      props.order.provider.data.data.pickup_stop.completed_at || props.order.provider.data.data.pickup_stop.estimated_arrival_time
                    ).format("LLLL")}
                  </Typography>
                  <a href={props.order.provider.data.data.pickup_stop.tracking.link}>Tracking</a>
                </Box>
                <Box my={2}>
                  <Typography variant="overline" color="textSecondary">
                    DROPFF STATUS
                  </Typography>
                  <Typography variant="body1">{props.order.provider.data.data.dropoff_stop.status}</Typography>
                  <Typography>
                    {moment(
                      props.order.provider.data.data.dropoff_stop.completed_at || props.order.provider.data.data.dropoff_stop.estimated_arrival_time
                    ).format("LLLL")}
                  </Typography>
                  <a href={props.order.provider.data.data.dropoff_stop.tracking.link}>Tracking</a>
                </Box>
                <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000" }}>{JSON.stringify(props.order.provider.data, null, 2)}</pre>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
